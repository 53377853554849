<template>
  <Draggable
    ghostClass="ghost"
    tag="tbody"
    v-bind="dragOptions"
    v-model="data.psystemsData"
    @start="dragging = true"
    @end="dragging = false"
    @change="onChange"
  >
    <slot></slot>
  </Draggable>
</template>
<script>
import Draggable from 'vuedraggable'
import PaySystemsService from '../../../services/api/pay-systems/pay.systems.service'

export default {
  components: {
    Draggable,
  },
  inject: ['data'],
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
  },
  data() {
    return {
      dragging: false,
    }
  },
  methods: {
    onChange(evt) {
      console.log('drag', evt.moved)
      this.updateSort(this.data.psystemsData)
    },
    async updateSort(psystemsData) {
      const psSort = psystemsData.map((ps, index, psArr) => {
        return {
          id: ps.id,
          sort: psArr.length - index,
        }
      })
      const sort = { sort: psSort }
      return PaySystemsService.updatePsystemSort(sort).then(() => {
        this.$message.success('Сортировка сохранена', 1)
      }).catch(() => {
        this.$message.error('Изменения в сортировке не были сохранены')
      })
    },
  },
}
</script>
